import { Amplify, Auth } from "aws-amplify";

import { Authenticator } from "@aws-amplify/ui-react";
import AppLayout from "@cloudscape-design/components/app-layout";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import logo from "./logo.svg";

import "@aws-amplify/ui-react/styles.css";

import RemindersTable from "./components/ReminderList";

Amplify.configure({
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_fSjPE9oV4",
  aws_user_pools_web_client_id: "535vj9iacla91sgs5t622ba71r",
  API: {
    endpoints: [
      {
        name: "reminderApi",
        endpoint: "https://oq9t9wlry9.execute-api.us-east-1.amazonaws.com/prod",
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
});

const i18nStrings = {
  searchIconAriaLabel: "Search",
  searchDismissIconAriaLabel: "Close search",
  overflowMenuTriggerText: "More",
  overflowMenuTitleText: "All",
  overflowMenuBackIconAriaLabel: "Back",
  overflowMenuDismissIconAriaLabel: "Close menu",
};

const profileActions = [
  { type: "button", id: "profile", text: "Profile" },
  { type: "button", id: "preferences", text: "Preferences" },
  { type: "button", id: "security", text: "Security" },
  {
    type: "menu-dropdown",
    id: "support-group",
    text: "Support",
    items: [
      {
        id: "documentation",
        text: "Documentation",
        href: "#",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "feedback",
        text: "Feedback",
        href: "#",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      { id: "support", text: "Customer support" },
    ],
  },
  { type: "button", id: "signout", text: "Sign out" },
];

export default function App() {
  return (
    <>
      <Authenticator>
        {({ signOut, user }) => (
          <>
            <TopNavigation
              i18nStrings={i18nStrings}
              identity={{
                href: "#",
                title: "Reminder App",
                logo: { src: logo, alt: "Service name logo" },
              }}
              utilities={[
                {
                  type: "button",
                  iconName: "notification",
                  ariaLabel: "Notifications",
                  badge: true,
                  disableUtilityCollapse: true,
                },
                {
                  type: "button",
                  iconName: "settings",
                  title: "Settings",
                  ariaLabel: "Settings",
                },
                {
                  type: "menu-dropdown",
                  text: user?.username,
                  description: user?.username,
                  iconName: "user-profile",
                  items: profileActions,
                  onItemClick: (event2) => {
                    console.log("my object: %o", event2.detail);
                    if (event2.detail.id === "signout" && signOut) {
                      signOut(event2);
                    }
                  },
                },
              ]}
            />

            <AppLayout
              navigationHide={true}
              toolsHide={true}
              content={
                <>
                  <h1>Hello {user?.username}</h1>
                  <RemindersTable />
                </>
              }
            />
          </>
        )}
      </Authenticator>
    </>
  );
}
