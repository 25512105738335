import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Table, { TableProps } from "@cloudscape-design/components/table";
import { API } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReminderCreate from "./ReminderNew";
import Modal from "@cloudscape-design/components/modal";

interface iReminder {
  id: string;
  title: string;
  description: string;
  createdAt: string;
  status: string;
}

export default function RemindersTable() {
  const [reminders, setReminders] = useState<iReminder[]>([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedItems, setSelectedItems] = useState<iReminder[]>([]);
  const tableRef = useRef<TableProps.Ref>(null);
  const [visible, setVisible] = useState(false);

  const loadReminders = () => {
    setIsTableLoading(true);
    API.get("reminderApi", "/reminders", {}).then((json) => {
      console.log(json);
      setReminders(json.reminders);
      setSelectedItems([]);
    });
    setIsTableLoading(false);
  };

  const deleteReminder = () => {
    if (selectedItems.length === 1) {
      const reminderId = selectedItems[0].id;
      API.del("reminderApi", `/reminder/${reminderId}`, {})
        .catch(() => {
          console.log("Failed to delete item: %s", reminderId);
        })
        .finally(() => {
          loadReminders();
        });
    }
  };

  const changeReminderStatus = (status: string) => {
    if (selectedItems.length === 1) {
      const reminderId = selectedItems[0].id;
      API.put("reminderApi", `/reminder/${reminderId}/${status}`, {})
        .catch(() => {
          console.log("Failed to disable item: %s", reminderId);
        })
        .finally(() => {
          loadReminders();
        });
    }
  };

  const disableReminder = () => {
    changeReminderStatus("disable");
  };

  const enableReminder = () => {
    changeReminderStatus("enable");
  };

  useEffect(() => {
    loadReminders();
  }, []);

  const handleActionButtons = (event: any) => {
    console.log("Action called, %o", event);
    // need to find the selected item id?
    const eventid = event.detail.id;
    switch (eventid) {
      case "bview":
        console.log("View Called");
        break;
      case "bdelete":
        console.log("Delete Called");
        deleteReminder();
        break;
      case "bdeactivate":
        console.log("Deactivate Called");
        disableReminder();
        break;
      case "bactivate":
        console.log("Active Called");
        enableReminder();
        break;
      default:
        console.log("Unhandled button clicked, %s", eventid);
        break;
    }
  };

  const onModelCompleted = (status: boolean) => {
    console.log("Modal completd", status);
    setVisible(false);
    loadReminders();
  };

  return (
    <>
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        header="Create Reminder"
      >
        <ReminderCreate onCompleted={onModelCompleted} />
      </Modal>
      <Table
        ref={tableRef}
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        columnDefinitions={[
          {
            id: "Title",
            header: "Title",
            cell: (item) => {
              const uri = `reminder/${item.id}`;
              return (
                <>
                  <Link to={uri}>{item.title}</Link>
                </>
              );
            },
            sortingField: "title",
            isRowHeader: true,
          },
          {
            id: "description",
            header: "Description",
            cell: (item) => item.description || "-",
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => {
              return <>{item.status}</>;
            },
          },
          {
            id: "createddate",
            header: "Created",
            cell: (item) => item.createdAt || "-",
          },
        ]}
        items={reminders}
        loadingText="Loading reminders"
        loading={isTableLoading}
        selectionType="single"
        sortingDisabled
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No reminders</b>
              <Button
                onClick={(e) => {
                  setVisible(true);
                }}
              >
                New Reminder
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  items={[
                    {
                      text: "Activate",
                      id: "bactivate",
                      disabled: false,
                    },
                    {
                      text: "Deactivate",
                      id: "bdeactivate",
                      disabled: false,
                    },
                    {
                      text: "View details",
                      id: "bview",
                      disabled: false,
                    },
                    {
                      text: "Delete",
                      id: "bdelete",
                      disabled: false,
                    },
                  ]}
                  onItemClick={handleActionButtons}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    setVisible(true);
                  }}
                >
                  Create
                </Button>
                <Button onClick={loadReminders}>Refresh</Button>
              </SpaceBetween>
            }
          >
            Reminders
          </Header>
        }
      />
    </>
  );
}
