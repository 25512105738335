import {
  Button,
  Form,
  FormField,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import {
  CancelableEventHandler,
  ClickDetail,
} from "@cloudscape-design/components/internal/events";
import { API } from "aws-amplify";
import { useState } from "react";

interface ReminderCreateProps {
  onCompleted?: (status: boolean) => void;
}

export default function ReminderCreate(props: ReminderCreateProps) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const onSubmit = (e: any) => {
    e.preventDefault();
    console.log("Form submitted");

    console.log(title, description);

    // get title
    if (title !== "" && description !== "") {
      API.post("reminderApi", "/reminder", {
        body: { title: title, description: description },
      }).then((json) => {
        console.log(json);
        setTitle("");
        setDescription("");
        props.onCompleted?.(true);
      });
    } else {
      alert("Title and description must not be empty");
    }
  };

  const cancelButtonClient = () => {
    props.onCompleted?.(false);
  };

  return (
    <>
      <form onSubmit={onSubmit}>
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                formAction="none"
                variant="link"
                onClick={cancelButtonClient}
              >
                Cancel
              </Button>
              <Button variant="primary">Submit</Button>
            </SpaceBetween>
          }
        >
          <FormField label="Title">
            <Input
              type="text"
              name="title"
              value={title}
              onChange={({ detail }) => setTitle(detail.value)}
            />
          </FormField>
          <FormField label="Description">
            <Input
              type="text"
              name="description"
              value={description}
              onChange={({ detail }) => setDescription(detail.value)}
            />
          </FormField>
        </Form>
      </form>
    </>
  );
}
